.jos, .jos-anchor {
  transition: all .4s ease-in-out;
}

.jos-anchor {
  opacity: 0;
  transition-timing-function: ease-in-out;
}

.jos-static {
  opacity: 1;
  transform: translate(0);
}

.jos-no-transition {
  transition-duration: 0s;
  transition-timing-function: linear;
}

.jos-hidden {
  display: none;
}

.jos-visible {
  opacity: 1;
  display: block;
}

.jos-fade {
  opacity: 0;
}

.jos-fade-right {
  opacity: 0;
  transform: translateX(-100px);
}

.jos-fade-left {
  opacity: 0;
  transform: translateX(100px);
}

.jos-fade-up {
  opacity: 0;
  transform: translateY(50px);
}

.jos-fade-down {
  opacity: 0;
  transform: translateY(-50px);
}

.jos-fade-right-up {
  opacity: 0;
  transform: translateX(-100px)translateY(50px);
}

.jos-fade-right-down {
  opacity: 0;
  transform: translateX(-100px)translateY(-50px);
}

.jos-fade-left-up {
  opacity: 0;
  transform: translateX(100px)translateY(50px);
}

.jos-fade-left-down {
  opacity: 0;
  transform: translateX(100px)translateY(-50px);
}

.jos-slide, .jos-slide-right {
  transform: translateX(-100px);
}

.jos-slide-left {
  transform: translateX(100px);
}

.jos-slide-up {
  transform: translateY(100px);
}

.jos-slide-down {
  transform: translateY(-100px);
}

.jos-slide-right-up {
  transform: translateX(-100px)translateY(100px);
}

.jos-slide-right-down {
  transform: translateX(-100px)translateY(-100px);
}

.jos-slide-left-up {
  transform: translateX(100px)translateY(100px);
}

.jos-slide-left-down {
  transform: translateX(100px)translateY(-100px);
}

.jos-zoom, .jos-zoom-out {
  opacity: 0;
  transform: scale(.8);
}

.jos-zoom-out-right, .jos-zoom-right {
  opacity: 0;
  transform: scale(.8)translate3d(-100px, 0, -100px);
}

.jos-zoom-left, .jos-zoom-out-left {
  opacity: 0;
  transform: scale(.8)translate3d(100px, 0, -100px);
}

.jos-zoom-down, .jos-zoom-out-down {
  opacity: 0;
  transform: scale(.8)translate3d(0, -100px, 0);
}

.jos-zoom-out-up, .jos-zoom-up {
  opacity: 0;
  transform: scale(.8)translate3d(0, 100px, 0);
}

.jos-grow {
  transform: scale(0);
}

.jos-grow-right {
  transform: scale(0)translate3d(-100px, 0, -100px);
}

.jos-grow-left {
  transform: scale(0)translate3d(100px, 0, -100px);
}

.jos-grow-down {
  transform: scale(0)translate3d(0, -100px, 0);
}

.jos-grow-up {
  transform: scale(0)translate3d(0, 100px, 0);
}

.jos-zoom-in {
  opacity: 0;
  transform: scale(1.1);
}

.jos-zoom-in-down {
  opacity: 0;
  transform: scale(1.1)translate3d(0, -100px, 0);
}

.jos-zoom-in-up {
  opacity: 0;
  transform: scale(1.1)translate3d(0, 100px, 0);
}

.jos-zoom-in-right {
  opacity: 0;
  transform: scale(1.1)translate3d(-100px, 0, -100px);
}

.jos-zoom-in-left {
  opacity: 0;
  transform: scale(1.1)translate3d(100px, 0, -100px);
}

.jos-shrink {
  transform: scale(1.4);
}

.jos-shrink-right {
  transform: scale(1.4)translate3d(-100px, 0, -100px);
}

.jos-shrink-left {
  transform: scale(1.4)translate3d(100px, 0, -100px);
}

.jos-shrink-down {
  transform: scale(1.4)translate3d(0, -100px, 0);
}

.jos-shrink-up {
  transform: scale(1.4)translate3d(0, 100px, 0);
}

.jos-flip, .jos-flip-right {
  opacity: 0;
  transform: perspective(2500px)rotateY(-100deg);
}

.jos-flip-left {
  opacity: 0;
  transform: perspective(2500px)rotateY(100deg);
}

.jos-flip-up {
  opacity: 0;
  transform: perspective(2500px)rotateX(-100deg);
}

.jos-flip-down {
  opacity: 0;
  transform: perspective(2500px)rotateX(100deg);
}

.jos-rotate, .jos-rotate-right {
  opacity: 0;
  transform: rotate(-180deg);
}

.jos-rotate-left {
  opacity: 0;
  transform: rotate(180deg);
}

.jos-spin, .jos-spin-right {
  opacity: 0;
  transform: rotate(-180deg)scale(0);
}

.jos-spin-left {
  opacity: 0;
  transform: rotate(180deg)scale(0);
}

.jos-revolve, .jos-revolve-right {
  opacity: 0;
  transform: rotate(-1turn)scale(0);
}

.jos-revolve-left {
  opacity: 0;
  transform: rotate(1turn)scale(0);
}

.jos-stretch {
  opacity: 0;
  transform: scaleX(0);
}

.jos-stretch-vertical {
  opacity: 0;
  transform: scaleY(0);
}

.jos-skew {
  opacity: 0;
  transform: skew(20deg, 20deg);
}

.jos-skew-right {
  opacity: 0;
  transform: skew(20deg, 20deg)translate3d(-100px, 0, -100px);
}

.jos-skew-left {
  opacity: 0;
  transform: skew(20deg, 20deg)translate3d(100px, 0, -100px);
}

.jos-skew-down {
  opacity: 0;
  transform: skew(20deg, 20deg)translate3d(0, -100px, 0);
}

.jos-skew-up {
  opacity: 0;
  transform: skew(20deg, 20deg)translate3d(0, 100px, 0);
}

.jos-slant {
  transform: skew(25deg, 25deg);
}

.jos-slant-right {
  transform: skew(25deg);
}

.jos-slant-left {
  transform: skew(0deg, 25deg);
}

.jos-bar, .jos-bar_vertical {
  transform: perspective(2500px)rotateY(-100deg);
}

.jos-bar_horizontal {
  transform: perspective(2500px)rotateX(-100deg);
}

.jos-grey {
  filter: grayscale();
}

.jos-blur {
  filter: blur(7px);
}

.jos-backdrop {
  backdrop-filter: blur(7px);
  opacity: .8;
}

.jos-invert {
  filter: invert();
}

.jos-sepia {
  filter: sepia();
}

.jos-saturate {
  filter: saturate();
}

.jos-hue-rotate {
  filter: hue-rotate(90deg);
}

.jos-brightness {
  filter: brightness(.5);
}

[data-jos_timing_function="ease"] {
  transition-timing-function: ease !important;
}

[data-jos_timing_function="ease-in"] {
  transition-timing-function: ease-in !important;
}

[data-jos_timing_function="ease-out"] {
  transition-timing-function: ease-out !important;
}

[data-jos_timing_function="ease-in-out"] {
  transition-timing-function: ease-in-out !important;
}

[data-jos_timing_function="linear"] {
  transition-timing-function: linear !important;
}

[data-jos_timing_function="step-start"] {
  transition-timing-function: step-start !important;
}

[data-jos_timing_function="step-end"] {
  transition-timing-function: step-end !important;
}

[data-jos_timing_function="steps"] {
  transition-timing-function: steps(5, end) !important;
}

[data-jos_timing_function="frames"] {
  transition-timing-function: frames(5) !important;
}

.jos-slide-horizontal-play, .jos-slide-play {
  transition: all 1s;
  animation: .7s ease-in-out infinite alternate-reverse jos-slide-play;
}

@keyframes jos-slide-play {
  0% {
    transform: translateX(-100px);
  }

  to {
    transform: translateX(100px);
  }
}

.jos-slide-vertical-play {
  animation: .7s ease-in-out infinite alternate-reverse jos-slide-vertical-play;
}

@keyframes jos-slide-vertical-play {
  0% {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(100px);
  }
}

.jos-pulse-play, .jos-zoom-in-play {
  animation: 1s infinite alternate jos-zoom-in-play;
}

@keyframes jos-zoom-in-play {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(.8);
  }
}

.jos-pulse-out-play, .jos-zoom-out-play, .jos-zoom-play {
  animation: .5s linear infinite alternate jos-zoom-play;
}

@keyframes jos-zoom-play {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(1.1);
  }
}

.jos-flip-play {
  animation: .7s infinite alternate jos-flip-play;
}

@keyframes jos-flip-play {
  0% {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(180deg);
  }
}

.jos-rotate-play {
  border-radius: 0;
  animation: 1s linear infinite alternate-reverse forwards jos-rotate-play;
}

@keyframes jos-rotate-play {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.jos-spin-play {
  animation: 1.5s infinite alternate jos-spin-play;
}

@keyframes jos-spin-play {
  0% {
    opacity: 0;
    transform: rotate(0);
  }

  to {
    opacity: 1;
    transform: rotate(1turn);
  }
}

.jos-revolve-play {
  animation: 1.5s infinite alternate jos-revolve-play;
}

@keyframes jos-revolve-play {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.jos-grow-play, .jos-shrink-play {
  animation: 1s infinite alternate jos-grow-play;
}

@keyframes jos-grow-play {
  0% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.jos-shrink-play {
  animation-direction: alternate-reverse;
}

.jos-stretch-play {
  animation: .7s infinite alternate jos-stretch-play;
}

@keyframes jos-stretch-play {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.jos-stretch-vertical-play {
  animation: .7s infinite alternate-reverse jos-stretch-vertical-play;
}

@keyframes jos-stretch-vertical-play {
  0% {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

.jos-fade-play {
  animation: .8s infinite alternate jos-fade-play;
}

@keyframes jos-fade-play {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.jos-fade-horizontal-play {
  animation: 1s infinite alternate-reverse jos-fade-horizontal-play;
}

@keyframes jos-fade-horizontal-play {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(-100px);
  }
}

.jos-fade-vertical-play {
  animation: 1s infinite alternate-reverse jos-fade-vertical-play;
}

@keyframes jos-fade-vertical-play {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(-100px);
  }
}

.jos-blink-play {
  animation: 1s step-end infinite jos-blink-play;
}

@keyframes jos-blink-play {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.77b5bf41.css.map */
